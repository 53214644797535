import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import Icon from '../Icon/Icon';
import { Axios, baseURL } from '../../utils/api/axios';

const NewsletterComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [Button, setButton] = useState('Envoyez');
  const [Loading, setLoading] = useState(false);
  const router = useNavigate();
  const onSubmit = async (data) => {
    setLoading(true);
    setButton('loading');
    if (data)
      Axios.post(baseURL + 'news-letter', {
        email: data.email,
      })
        .then(({ data }) => {
          setLoading(false);
          if (data?.success) {
            setButton('Succès');
            reset();
          } else {
            setButton('Echoué');
            toast.error('Echoué');
          }
        })
        .catch(() => {
          setLoading(false);
          setButton('Echoué');
          // throw new Error('Network response was not ok');
        })
        .finally(() => {
          setTimeout(() => {
            setButton('Envoyez');
          }, 1000);
        });
  };

  return (
    <div
      id='contact'
      className='w-[99%] md:w-[70%] mx-auto p-8 text-center md:text-left font-display'
    >
      <h2 className='text-[48px] font-normal mb-4'>Comment vous aider?</h2>
      <p className='mb-10 md:mb-6 md:text-[24px] text-[19px] font-normal text-[#00000099] '>
        Suivez notre newsletter. Nous mettrons régulièrement à jour nos derniers
        projets et disponibilités.
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='flex flex-col md:flex-row gap-3'
      >
        <input
          type='email'
          placeholder='Tapez votre email'
          {...register('email', { required: 'Email is required' })}
          className='w-full md:w-[73%] font-normal text-[19px] border rounded-[44px]  md:py-[24px] py-[18px] px-[30px] md:px-[46px] bg-black text-white placeholder-white'
        />

        <button
          type='submit'
          disabled={Loading}
          className=' bg-[#1576FE] w-full md:w-[25%] font-normal text-[19px] text-white  md:py-[24px] md:px-[28px] py-[15px] px-[28px] rounded-[44px]'
        >
          <div
            className=' justify-center m-auto align-center min-w-[100%]'
            style={{ gap: 20, alignItems: 'center', display: 'flex' }}
          >
            {Button} {Loading && <Icon name='loading-icon' />}
          </div>
        </button>
      </form>
    </div>
  );
};

export default NewsletterComponent;
