import React from 'react';
import Icon from '../Icon/Icon';
import Calendar_Planifier from '../Calendar/Calendar';

function Planifier({ register, errors, watch, Onsubmit, setValue }) {
  return (
    <form onSubmit={Onsubmit} className='item-container'>
      <div className='container'>
        <h4>Sélectionnez l'heure de début </h4>
        <p>
          Choisissez l'heure du rendez-vous pour la visite. La durée estimée de
          la visite est d'environ 60 minutes.
        </p>

        <Calendar_Planifier {...{ setValue, watch, errors }} />
      </div>
      <div className='right'>
        <h4 className='bold'>Prix estimé</h4>
        <p className='bold'>Adresse</p>
        <p>
          {watch('address')} {watch('ville')} {watch('pays')}
        </p>
        <br />
        <p className='bold'>Détails de la numérisation</p>
        <p>
          type: {watch('numerisation')}. <br />
          {watch('nom_capturer')}
        </p>
        <br />
        <p className='bold'>Détails de la propriété</p>
        <p>Un espace totalisant {watch('superficie')} m2</p>
        <div className='flex bold' style={{ justifyContent: 'space-between' }}>
          Capture services <span>{Number(watch('superficie')) * 25} MAD</span>
        </div>
        <button style={{ marginTop: 20 }} className='add '>
          Suivant
        </button>
      </div>
    </form>
  );
}

export default Planifier;
