import React from 'react';
import './StepsCapt.css';
import { Link } from 'react-router-dom';

function StepsCapt() {
  return (
    <div className='StepsCapt'>
      <video loop autoPlay muted src='/videos/kitchen.mp4'></video>
      <div className='espaces'>
        <div>
          <h1 className='font-display-medium'>
            Numérisez <br />
            <span>vos espaces </span>
          </h1>
          <div>
            <p>
              La méthode la plus sûre et la plus commode pour obtenir un modèle
              numérique de haute qualité de votre espace. C'est très simple !
              Envoyez-nous une description de votre espace, indiquez-nous quand
              et comment nous pouvons intervenir, et nous prendrons en charge le
              reste ! Service maintenant disponible dans plusieurs régions.
              <li>Numérisation effectuée pour vous</li>
              <li>Caméra Matterport haut de gamme</li>
              <li>Technicien spécialisé en capture 3D</li>
            </p>
            <Link to={'/detail'}>
              <button className='reserve'>
                Réservez votre visite virtuelle
              </button>
            </Link>
          </div>
        </div>
        <img src='/images/phone.png' alt='' />
      </div>
      <div className='my-14'>
        <h3 className='text-[40px] font-medium mb-10 text-center md:text-left'>
          Processus de Commande
        </h3>
        <div className='flex flex-col md:flex-row w-full bg-black h-fit md:h-[243px] text-white'>
          <div className='w-full h-[243px] md:w-1/3 py-10 px-10 flex justify-center items-start gap-5 flex-col relative'>
            <h4 className='font-bold text-[24px] '>COMMANDER</h4>
            <p className='font-normal text-[18px] '>
              Commencez par envoyer une demande avec l'adresse de votre espace
              ainsi que le créneau horaire souhaité pour la numérisation.
            </p>
            <span className='absolute -bottom-5 right-7 text-[65px] md:text-[79px] text-[#1576FE] font-[900] '>
              1
            </span>
          </div>
          <div className='w-full h-[243px] md:w-1/3 bg-[#1576FE] py-10 px-10 flex relative justify-center items-start gap-5 flex-col'>
            <h4 className='font-bold text-[24px] '>CAPTURER</h4>
            <p className='font-normal text-[18px] '>
              Un technicien certifié viendra <br /> numériser votre espace en
              utilisant <br /> notre appareil photo Matterport de <br /> pointe.
            </p>
            <span className='absolute -bottom-5 right-7 text-[65px] md:text-[79px]  font-[900] '>
              2
            </span>
          </div>
          <div className='w-full h-[243px] md:w-1/3 py-10 px-10 flex relative justify-center items-start gap-5 flex-col'>
            <h4 className='font-bold text-[24px] '>PRÉSENTER</h4>
            <p className='font-normal text-[18px] '>
              Vous recevrez un modèle numérique de votre espace avec des options{' '}
              <br /> complètes de retouche et de <br /> personnalisation.
            </p>
            <span className='absolute -bottom-5 right-7 text-[65px] md:text-[79px] text-[#1576FE] font-[900] '>
              3
            </span>
          </div>
        </div>
        <div className='text-center w-full'>
          <Link to={'/detail'}>
            <button className='reserve mt-14 justify-self-center'>
              Réservez votre visite virtuelle
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default StepsCapt;
