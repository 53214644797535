import React from 'react';
import './Services.css';
import { Link } from 'react-router-dom';

function Services() {
  return (
    <div className='Services' id='services'>
      <h2>
        Profitez de nos <br />
        <span>Services</span>
      </h2>
      <div className='card'>
        <img src='/images/restau.jpg' alt='' />
        <div>
          <h3>Visites Virtuelles</h3>
          <p>
            Découvrez votre établissement sous un nouvel angle avec nos visites
            virtuelles immersives. Offrez à vos clients une expérience unique en
            leur permettant de visiter votre espace en ligne, comme s’ils y
            étaient. Augmentez votre visibilité et attirez davantage de
            visiteurs grâce à notre technologie de pointe.
          </p>
        </div>
      </div>
      <div className='card c-2'>
        <img src='/images/restau1.jpg' alt='' />
        <div>
          <h3>Photos HDR</h3>
          <p>
            Capturez la beauté de votre Restaurant avec nos photos HDR de haute
            qualité. Nos experts photographes utilisent des techniques avancées
            pour produire des images époustouflantes qui mettent en valeur
            chaque détail et créent une ambiance unique. Donnez vie à vos
            espaces avec des images qui impressionnent et captivent.
          </p>
        </div>
      </div>
      <h2>Visite Virtuelle</h2>
      <img src='/images/vv.jpg' alt='' />

      <div className='shrp-text card'>
        <img src='/images/iPhone.png' alt='' />
        <div>
          <h3>Qu'est-ce que la visite virtuelle Google Street View ?</h3>
          <p>
            Aujourd’hui, il est essentiel de comprendre que Google privilégie
            les entreprises proposant des services locaux dans ses résultats de
            recherche, que ce soit dans le commerce, la restauration, les
            services ou l’hébergement. En intégrant une visite virtuelle 360°
            sur Google de votre lieu, vous pouvez considérablement améliorer
            votre présence dans les résultats de recherche ainsi que sur Google
            Maps.
          </p>
        </div>
      </div>
      <div className='formulaire'>
        <video loop autoPlay muted src='/videos/v2.mp4'></video>
        <img src='/images/Capture service formulaire.png' alt='' />
      </div>
      <div className='flex justify-center mt-20'>
        <Link to={'/detail'}>
          <h3 className='bg-[#377DFF] text-white text-[21px] font-normal rounded-[12px] py-[19px] cursor-pointer px-5 w-[250px] flex items-center justify-center'>
            Réservez une visite
          </h3>
        </Link>
      </div>
      <h2>Rencontrez notre équipe</h2>
      <div className='users'>
        {['/images/u1.png', '/images/u2.png', '/images/u3.png'].map(
          (img, i) => (
            <div key={i}>
              <img src={img} alt='' />
              <div>
                <div>
                  <h4>Rachid Moufakkir</h4>
                  <p>Photograph & Vidéaste</p>
                </div>
                <img src='/images/gsv.png' alt='' />
              </div>
            </div>
          )
        )}
      </div>
      <div className='card'>
        <img src='/images/home.png' alt='' />
        <div>
          <h3>
            Nous cartographions
            <br /> votre lieu avec une
            <br /> précision immersive
          </h3>
          <p>
            Tous les internautes qui recherchent votre établissement sur Google
            Maps ou via le moteur de recherche Google peuvent dorénavant accéder
            à votre visite virtuelle. Qu’il s’agisse d’un ordinateur, d’une
            tablette, d’un téléphone portable ou même d’un casque de réalité
            virtuelle, chacun pourra explorer votre entreprise depuis son
            appareil préféré.
          </p>
        </div>
      </div>
      <h2>Notre Logiciel</h2>
      <img
        style={{ maxWidth: '1000px', margin: 'auto', marginTop: '7rem' }}
        src='/images/3d_cam.png'
        alt=''
      />

      <div className='Hero' style={{ marginTop: '7rem' }}>
        <img src='/images/image-1.png' />
        <div>
          <h1>
            Avec Map
            <span
              style={{
                fontFamily: 'Pacifico',
              }}
            >
              lead
            </span>
          </h1>
          <p>
            Vous pouvez aussi facilement partager votre visite virtuelle sur les
            réseaux sociaux (Facebook, Google+) et l’intégrer sur votre site
            Web.{' '}
          </p>
          <Link to={'/detail'}>
            <button className='reserve'>Réservez votre visite virtuelle</button>
          </Link>
        </div>
      </div>
      <div className='text-center mb-10 font-display'>
        <h3 className='text-[4rem] md:text-[6rem] font-semibold mb-10'>
          Pourquoi utiliser Google Maps 360
        </h3>
        <span className='text-[3rem] md:text-[3.7rem] font-medium mb-14'>
          Un outil incontournable avec d'innombrables possibilités
        </span>
      </div>
      <div className='text-center mb-10'>
        <p className='text-[30px] font-normal my-10 '>Sur l’échelle Mondiale</p>
        <img src='/images/Map.svg' />
      </div>
      <div
        className=' mb-10 gap-5'
        style={{
          display: 'grid',
          'grid-template-columns': 'repeat(auto-fit, minmax(220px, 1fr))',
        }}
      >
        <div className='p-5 flex-1 md:text-left'>
          {' '}
          <h4 className='font-semibold text-[3.8rem] '>VISIBILITÉ</h4>
          <p className='font-normal text-[1.8rem] '>
            Plus de visibilité sur Google (Search, Google Maps), véritable
            levier de promotion.
          </p>
        </div>
        <div className='p-5 flex-1  md:text-left'>
          {' '}
          <h4 className='font-semibold text-[3.8rem] '>OPTIMISATION</h4>
          <p className='font-normal text-[1.8rem] '>
            Augmente le référencement naturel (permet d'être épinglé sur la
            toile)
          </p>
        </div>
        <div className='p-5 flex-1  md:text-left'>
          {' '}
          <h4 className='font-semibold text-[3.8rem] '>ENGAGEMENT</h4>
          <p className='font-normal text-[1.8rem] '>
            Susciter l’intérêt de votre visiteur
          </p>
        </div>
        <div className='p-5 flex-1  md:text-left'>
          {' '}
          <h4 className='font-semibold text-[3.8rem] '>ANALYTIQUES</h4>
          <p className='font-normal text-[1.8rem] '>
            Collecter et analyser des données
          </p>
        </div>
      </div>
    </div>
  );
}

export default Services;
