import React from 'react';

function Input({
  register = () => {},
  errors = {},
  lable = '',
  name = '',
  type = 'text',
  attr = {},
  className = '',
}) {
  return (
    <div className={`input-container ${className}`}>
      <label className='bold' htmlFor=''>
        {lable}
      </label>
      <input
        className={`${errors && errors[name] ? 'error' : ''}`}
        {...register(name, { valueAsNumber: type === 'number' })}
        placeholder={lable}
        type={type}
        {...attr}
      />
      <span className='error'>
        {`${errors && errors[name]?.message ? errors[name]?.message : ''}`}
      </span>
    </div>
  );
}

export default Input;
