import React, { useEffect, useState } from 'react';
import './Popup.css';
import Icon from '../../Icon/Icon';
import { Link } from 'react-router-dom';

function Popup1({ setShowPopup, setValue, watch }) {
  return (
    <div className='Popup'>
      <div className='overlay' onClick={() => setShowPopup(false)}></div>
      <div className='Card'>
        <div className='flex'>
          <img src='/images/3D1.svg' alt='' srcset='' />
          <div>
            <h3>Plan schématique de niveau</h3>
            <span>Par MapLead</span>
            {/* <button>Obtenir</button> */}
          </div>
        </div>
        <p>
          Plans d'étage schématiques professionnels pour la promotion d'espaces
          résidentiels et de locaux commerciaux.
        </p>
        <img src='/images/plan.png' alt='' />

        <br />
        <strong>Informations</strong>

        <p>
          Les plans schématiques de niveau sont un atout essentiel du marketing
          immobilier pour susciter l'intérêt des acheteurs. Lorsque vous
          commandez un plan de niveau MapLead, nous faisons le gros du travail
          pour vous envoyer un fichier prêt à l'emploi qui vous évitera de
          devoir mesurer toute la propriété à la main. Il vous suffit juste de
          la numériser.
        </p>
        <p>
          Les plans d'étage sont prêts à être présentés dès leur livraison et
          peuvent être personnalisés par l'utilisateur. Les fichiers vectoriels
          (SVG) inclus vous permettent de modifier rapidement les
          caractéristiques du plan d'étage tout en conservant le niveau de
          détails.
        </p>

        <br />
        <strong>Normes de mesure</strong>

        <p>
          Vos plans de niveau seront mesurés sur la base de la norme
          internationale de mesure des propriétés (IPMS). Les bâtiments
          résidentiels sont mesurés conformément aux normes de l'IPMS
          Residential 3B, à l'exception de la surface au sol des balcons et des
          terrasses qui est indiquée séparément. Les bureaux sont mesurés
          conformément aux normes de l'IPMS pour les immeubles de bureaux.
        </p>
        <br />
        <p>
          Veuillez noter que les plans d'étage schématiques ne peuvent pas être
          personnalisés afin de tenir compte des différentes juridictions ou des
          différents systèmes de calcul des surfaces. Veuillez vérifier que ces
          plans répondent aux exigences et aux cas d'utilisation souhaités.
        </p>
        <strong>Conseils pour la numérisation</strong>
        <p>
          Afin d’optimiser la précision de vos plans, veuillez numériser
          entièrement chaque pièce en évitant les erreurs d’alignement ou tout
          autre défaut visuel. Pour en savoir plus, nous vous invitons à
          consulter nos guides dédiés à la numérisation.
        </p>
        <strong>Informations complémentaires</strong>
        <br />
        <ul>
          <li>
            Pour créer un plan de niveau, les Espaces doivent être publics ou
            non répertoriés. . Le traitement des Espaces qui ne sont pas publics
            ou non répertoriés sera différé jusqu’à ce qu’ils soient rendus
            publics ou non répertoriés.
          </li>
          <li>
            Livraison sous 2 jours ouvrés, hors week-end et jours fériés. Pour
            être livré plus rapidement, consultez nos plans de d'étage rapide ou
            express.
          </li>
          <li>
            Toutes les étiquetages des pièces sont dans la langue choisie. Si
            les étiquettes personnalisées sont cochées et étiquetées dans le
            modèle, elles seront utilisées
          </li>
          <li>
            Tous les plans de niveau sont livrés au format PNG, PDF et SVG.
          </li>
          <li>
            Disponible pour tous les forfaits, à l'exclusion du forfait gratuit.
          </li>
          <li>
            Les Espaces créés avec une caméra à profondeur active telle que
            MapLead Pro3, Pro2, Pro2 Lite, Pro ou Leica BLK360 fourniront des
            résultats plus précis. Les Espaces capturés avec des caméras à 360°
            (gamme Ricoh Theta, Insta360 ONE) ou des appareils mobiles iOS ou
            Android reposent sur une profondeur synthétisée et peuvent être
            moins précis.
          </li>
          <li>
            La précision dépend de nombreux facteurs (environnement, densité de
            la numérisation, appareils utilisés). Vérifiez deux fois les valeurs
            lorsque cela est nécessaire pour des opérations critiques.
          </li>
          <li>
            Les plans schématiques de niveau ne sont pas disponibles pour les
            espaces de plus de 2 300 m².
          </li>
          <li>
            Si la superficie de la propriété dépasse 10 000 pieds carrés
            (930 m²), la livraison accélérée, le mobilier, la couleur 2D et la
            sélection de la langue ne sont pas disponibles.
          </li>
          <li>
            Veuillez examiner votre espace dans la vue du plan avant d’effectuer
            une commande, car des erreurs d’alignement peuvent entraîner des
            retards ou des annulations.
          </li>
          <li>
            Si vous avez des questions, consultez Comment commander un plan
            schématique de niveau.
          </li>
        </ul>
        {/* <Link to={'/'}>
          <Icon name='telecharger' /> <span>Télécharger le modèle</span>
        </Link>
        <Link to={'/'}>
          <Icon name='link' /> <span>Page d'aide</span>
        </Link> */}
      </div>
      <div className='end-footer'>
        <p>Prix : 1200 MAD</p>

        <div className='buttons'>
          <button onClick={() => setShowPopup(false)}>Fermer</button>
          <button
            className={watch('schématique') ? 'active' : ''}
            onClick={() => {
              setValue('schématique', !watch('schématique'));
              setTimeout(() => {
                setShowPopup(false);
              }, 1000);
            }}
          >
            <Icon name={watch('schématique') ? 'checkbox' : 'add-cart'} />{' '}
            {watch('schématique') ? 'Ajouté' : 'Ajouter au panier'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Popup1;
