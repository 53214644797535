import React from 'react'
import './Success.css'
import Icon from '../Icon/Icon'
import { Link } from 'react-router-dom'

function Success() {
  return (
    <div className='Success-popup'>
        <div className="container">
            <h1>
                <Icon name='success'/>
                Succès
            </h1>
            <p>
            Votre commande a été effectuée avec succès
            </p>
            <Link to={'/'}>
            Fermer
            </Link>
        </div>
    </div>
  )
}

export default Success