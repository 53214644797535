import React from 'react';
import './Food.css';
import { Link } from 'react-router-dom';

function Food() {
  return (
    <div className='Food'>
      <div className='top'>
        <div>
          <h4>Configurez et commandez </h4>
          <p>
            Obtenez une estimation instantanée et passez commande pour votre
            bien ou commerce en quelques clics.
          </p>
        </div>
        <div>
          <h4>Prise de contact rapide</h4>
          <p>
            Nous vous contactons rapidement pour organiser le déplacement de
            notre photographe agréé.
          </p>
        </div>
        <div>
          <h4>Recevez votre visite virtuelle</h4>
          <p>
            Une fois créée avec soin, vous recevrez un lien en ligne pour la
            visionner et la partager instantanément, ainsi que les fichiers
            sources téléchargeables pour une utilisation flexible.
          </p>
        </div>
        {/* <div></div> */}
      </div>
      <div className='images'>
        <img src='/images/food1.jpg' alt='' />
        <img src='/images/food2.jpg' alt='' />
        <img src='/images/food3.jpg' alt='' />
        <img src='/images/food4.jpg' alt='' />
      </div>
      <img src='/images/apps-logo.png' />
      <h2>
        AJOUTEZ UNE VISITE VIRTUELLE DE VOTRE ÉTABLISSEMENT SUR GOOGLE MAPS ET
        AUGMENTEZ VOTRE VISIBILITÉ !
      </h2>
      <p>
        Il faut savoir qu’aujourd’hui, Google favorise les résultats de
        recherche des entreprises offrant des services de proximité (commerce,
        restauration, service, hébergement, …). Grâce à l’ajout d’une visite
        virtuelle Google Street View de votre établissement, vous augmenterez
        votre visibilité sur Google Maps et les recherches sur le moteur de
        Google.
      </p>
      <Link
        style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}
        to={'/detail'}
      >
        <button className='reserve'>Réservez votre visite virtuelle</button>
      </Link>
    </div>
  );
}

export default Food;
