import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Politiques = () => {
  return (
    <>
      <div className='Conditions container' id='hero'>
        <Header />
        <h1>Politiques de Confidentialité</h1>
        <p>
          1. Collecte des informations : Nous collectons les informations
          necessaires pour creer votre compte, telles que votre nom, adresse
          e-mail, et coordonnees de contact. Ces informations sont utilisees
          pour la gestion de votre compte et l'execution des services commandes.{' '}
        </p>
        <p>
          2. Utilisation des donnees : Vos donnees personnelles sont utilisees
          uniquement dans le cadre de la gestion des services que nous
          fournissons. Nous ne partageons pas vos informations avec des tiers
          sans votre consentement prealable.{' '}
        </p>
        <p>
          3. Securite des donnees : Nous mettons en oeuvre des mesures de
          securite rigoureuses pour proteger vos informations personnelles.
          Cependant, aucune methode de transmission sur Internet n'est
          totalement securisee et nous ne pouvons garantir une securite absolue.{' '}
        </p>
        <p>
          4. Cookies : Notre plateforme utilise des cookies pour ameliorer
          l'experience utilisateur. Vous pouvez désactiver les cookies via les
          parametres de votre navigateur, mais cela peut affecter le
          fonctionnement de certaines fonctionnalites.{' '}
        </p>
        <p>
          5. Conservation des donnees : Vos donnees sont conservees aussi
          longtemps que necessaire pour la fourniture des services, sauf si vous
          demandez la suppression de votre compte.{' '}
        </p>
        <p>
          6. Droits des utilisateurs : Vous avez le droit d'acceder, de
          modifier, ou de demander la suppression de vos informations
          personnelles a tout moment en nous contactant.{' '}
        </p>
        <p>
          7. Modifications de la politique de confidentialite : Nous nous
          reservons le droit de mettre a jourcette politique de confidentialite.
          Nous vous informerons de tout changement significatif via notre
          plateforme ou par e-mail.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Politiques;
