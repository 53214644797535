import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import { Toaster } from 'react-hot-toast';
import Réserver_Solutions_de_Capture_Numérique from './pages/Réserver_Solutions_de_Capture_Numérique/Réserver_Solutions_de_Capture_Numérique';
import Home from './pages/Home/Home';
import Conditions from './pages/Conditions/Conditions';
import Politiques from './pages/Politiques/Politiques';
import Contact from './pages/Contact/Contact';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/conditions-utilisation',
      element: <Conditions />,
    },
    {
      path: '/politiques-confidentialité',
      element: <Politiques />,
    },

    {
      path: '/detail',
      element: (
        <div className=''>
          <Home>
            <Réserver_Solutions_de_Capture_Numérique />
          </Home>
        </div>
      ),
      errorElement: <></>,
    },
    {
      path: '/contact',
      element: (
        <div className=''>
          <Home>
            <Contact />
          </Home>
        </div>
      ),
      errorElement: <></>,
    },

    {
      path: '/:id',
      element: <Home />,
      errorElement: <></>,
    },
  ]);

  return (
    <div className='App'>
      <RouterProvider router={router}></RouterProvider>
      <Toaster position='top-right' />
      <div className='container'></div>
    </div>
  );
}

export default App;
