import React from 'react';
import './Popup.css';
import Icon from '../../Icon/Icon';
import { Link } from 'react-router-dom';

function Popup({ setShowPopup, setValue, watch }) {
  return (
    <div className='Popup'>
      <div className='overlay' onClick={() => setShowPopup(false)}></div>

      <div className='Card'>
        <div className='flex'>
          <img src='/images/gsv-icon.png' alt='' srcset='' />
          <div>
            <h3>Google Street View</h3>
            <span>Par Maplead</span>
            {/* <button>Obtenir</button> */}
          </div>
        </div>
        <p>
          Ajoutez votre entreprise sur la carte avec l'intégration Google Street
          View de Maplead. Maplead vous permet de publier facilement votre
          espace afin d'accroître sa visibilité.
        </p>
        <br />
        <strong>Informations</strong>
        <p>
          Nous nous sommes associés à Google pour vous permettre de publier,
          rapidement et en quelques clics, un jumeau numérique de votre espace
          dans Google Street View à partir de votre compte Maplead. Cette
          intégration automatise et accélère le processus de prise de vue et
          d'assemblage d'images panoramiques à 360˚.
        </p>

        <p>
          Avec Maplead et Google Street View, tout le monde peut voir votre
          boutique. Cela permet d'améliorer l'expérience des internautes qui ont
          visité votre entreprise en ligne avant de se rendre dans votre point
          de vente.
        </p>
        <br />
        <strong>Informations complémentaires</strong>

        <p>
          Pour être publiés sur Google Street View, les Espaces doivent être
          publics ou non répertoriés.
        </p>
        <p>
          Toutes les images et connexions devraient être disponibles sur Google
          Maps, Google Earth et Google Street View dans un délai de 24 à 48 h.
          Vous recevrez un e-mail lorsque votre modèle aura été publié.
        </p>
        <p>
          Les maisons de particuliers (résidences) ne peuvent pas être publiées
          sur Google Street View. Les locations de vacances à court terme sont
          autorisées.
        </p>
        <br />
        {/* <strong>Documents et exemples</strong>
        <br />
        <Link to={'/'}>
          <Icon name='link' /> <span>Page d'aide</span>
        </Link> */}
      </div>
      <div className='end-footer'>
        <p>Prix : 980 MAD</p>

        <div className='buttons'>
          <button onClick={() => setShowPopup(false)}>Fermer</button>
          <button
            className={watch('street_view') ? 'active' : ''}
            onClick={() => {
              setValue('street_view', !watch('street_view'));
              setTimeout(() => {
                setShowPopup(false);
              }, 1000);
            }}
          >
            <Icon name={watch('street_view') ? 'checkbox' : 'add-cart'} />{' '}
            {watch('street_view') ? 'Ajouté' : 'Ajouter au panier'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Popup;
