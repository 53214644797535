import React from 'react';
import Card from './Card';
import PackComponent from './PackComponent';

const Plans = () => {
  const services = [
    {
      title: 'Pack Photos HDR',
      image: '/images/plans/plan1.png',
      description:
        'Nous réalisons pour vous un ensemble de photos HDR de haute qualité pour capturer chaque détail de votre restaurant ou hôtel. Notre équipe s’assure que votre espace soit présenté de manière optimale, avec des images qui subliment la lumière et les couleurs.',
    },
    {
      title: 'Visite Virtuelle',
      image: '/images/plans/plan2.png',
      description:
        'Nous créons pour vous une visite virtuelle immersive et interactive de votre établissement. Grâce à notre expertise, vos clients peuvent explorer chaque recoin de votre espace à distance, comme s’ils y étaient, directement depuis leur écran.',
    },
    {
      title: "Cloud 10GB d'espace",
      image: '/images/plans/plan3.png',
      description:
        'Nous mettons à votre disposition 10 Go d’espace de stockage sécurisé dans le cloud, où vous pouvez gérer et stocker facilement vos contenus multimédias, y compris vos photos HDR et visites virtuelles. Nous assurons une gestion fluide et sécurisée de vos données.',
    },
    {
      title: 'Mise a jour trimestrielle',
      image: '/images/plans/plan4.png',
      description:
        'Notre équipe procède à une mise à jour trimestrielle de votre contenu virtuel. Nous veillons à ce que les modifications et améliorations apportées à votre espace soient toujours reflétées dans vos visites virtuelles et photos.',
    },
    {
      title: 'Publication (Google Street View)',
      image: '/images/plans/plan5.png',
      description:
        'Nous publions votre établissement directement sur Google Street View, offrant ainsi une visibilité mondiale à votre restaurant ou hôtel. Notre équipe se charge de toute l’intégration pour que vos clients puissent explorer virtuellement votre espace.',
    },
    {
      title: 'Video de Restautant',
      image: '/images/plans/plan6.png',
      description:
        'Nous réalisons une vidéo captivante de la visite virtuelle de votre restaurant. Grâce à notre expertise, nous capturons les points forts de votre établissement et créons une présentation immersive, permettant à vos clients de découvrir votre espace de manière dynamique et interactive. Cette vidéo met en valeur chaque détail, renforçant l’attrait de votre établissement auprès de vos futurs clients.',
    },
    {
      title: "Statistiques d'engagements",
      image: '/images/plans/plan7.png',
      description:
        'Nous vous fournissons des rapports détaillés sur l’engagement de vos visiteurs avec vos contenus virtuels. Grâce à nos outils d’analyse, vous pouvez suivre et comprendre comment vos clients interagissent avec vos visites et vidéos, afin d’optimiser votre stratégie marketing.',
    },
    {
      title: 'Integration de Menu Interactif',
      image: '/images/plans/plan8.png',
      description:
        'Nous intégrons pour vous un menu interactif directement dans la visite virtuelle de votre restaurant. Vos clients peuvent ainsi consulter et commander vos plats facilement via leur smartphone, offrant une expérience utilisateur fluide et innovante.',
    },
  ];

  return (
    <div className='text-center my-14 font-display'>
      <h3 className='text-[5rem] md:text-[7rem] font-display-bold font-bold mb-10'>
        Optez pour notre plan.
      </h3>
      <p className='text-[23px] md:text-[26px] font-bold text-[#A6A6A6] mb-14'>
        Optez pour notre plan unique, et n'hésitez pas à nous contacter.
      </p>
      <button className='font-semibold text-[26px] w-[300px] md:w-[477px] rounded-[14px] text-white bg-[#1576FE] py-[18px] px-[53px] '>
        Par An
      </button>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-y-14 gap-x-14 my-10'>
        {services?.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </div>
      <PackComponent />
    </div>
  );
};

export default Plans;
