import React from 'react';
import './Conditions.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Conditions = () => {
  return (
    <>
      <div className='Conditions container' id='hero'>
        <Header />
        <h1>Conditions d'utilisation</h1>
        <p>
          1. Acceptation des termes : En utilisant notre plateforme, vous
          acceptez d'etre lie par les presentes conditions d'utilisation.{' '}
        </p>
        <p>
          2. Creation de compte : Vous devez creer un compte sur notre
          plateforme pour acceder a nos services. Vous etes responsable de la
          confidentialite de votre compte et de vos informations de connexion.{' '}
        </p>
        <p>
          3. Commande de services : En passant une commande de visite virtuelle
          ou de publication sur Google Street View, vous vous engagez a fournir
          des informations exactes et a respecter les delais definis.{' '}
        </p>
        <p>
          4. Droits de propriété : Toutes les visites virtuelles, photos HDR,
          videos et autres contenus crees pour vous sont soumis aux droits de
          propriete intellectuelle. Une fois le projet finalise, vous avez le
          droit d'utiliser ces contenus a des fins promotionnelles.{' '}
        </p>
        <p>
          5. Responsabilité : Nous ne sommes pas responsables des retards dus a
          une mauvaise préparation de votre espace ou a des informations
          incorrectes fournies lors de la commande.{' '}
        </p>
        <p>
          6. Mise a jour de contenu : Vous pouvez beneficier de mises a jour
          trimestrielles de vos visites virtuelles. Cependant, toute mise a jour
          additionnelle en dehors de ce cadre peut entrainer des frais
          supplémentaires.{' '}
        </p>
        <p>
          7. Annulation et reprogrammation : Toute annulation ou reprogrammation
          de session doit etre faite48 heures a l'avance. Dans le cas contraire,
          des frais peuvent s'appliquer.{' '}
        </p>
        <p>
          8. Modification des conditions : Nous nous reservons le droit de
          modifier ces conditions a tout moment. Il est de votre responsabilite
          de consulter regulierement les mises a jour.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Conditions;
