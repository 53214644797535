import React from "react";
import "./Steps.css";
import Icon from "../Icon/Icon";
import { useSearchParams } from "react-router-dom";

function Steps() {
  const [params, setParams] = useSearchParams();
  const tab = Number(params.get("tab"));
  const steps = [
    "Détail du Restaurant",
    "Planification & prise de rendez vous",
    "Coordonnées de la personne de contact",
    "Vérification des informations fournies",
    "Choix de modèles complémentaires",
    "vérification & paiement",

  ];
  return (
    <div className="Steps container">
      <div className="title">
        <span> étape {(tab||0)+1} :</span> {steps[(tab||0)]}
      </div>
      <ul className="list">
        <div className="line">
          <span style={{ width: tab * 25 + "%" }}></span>
        </div>
        <li
          onClick={() => {
            params?.set("tab", 1);
            setParams(params);
          }}
          className={tab > 0 ? "active" : ""}
        >
          {" "}
          <button>
            {" "}
            <Icon name="calendar1" />
          </button>{" "}
          Planifier
        </li>
        <li
          onClick={() => {
            params?.set("tab", 2);
            setParams(params);
          }}
          className={tab > 1 ? "active" : ""}
        >
          {" "}
          <button>
            {" "}
            <Icon name="user1" />
          </button>{" "}
          Contact
        </li>
        <li
          onClick={() => {
            params?.set("tab", 3);
            setParams(params);
          }}
          className={tab > 2 ? "active" : ""}
        >
          {" "}
          <button>
            {" "}
            <Icon name="eye1" />
          </button>{" "}
          Vérifier
        </li>
        <li
          onClick={() => {
            params?.set("tab", 4);
            setParams(params);
          }}
          className={tab > 3 ? "active" : ""}
        >
          {" "}
          <button>
            {" "}
            <Icon name="box1" />
          </button>{" "}
          <p>
            Modules
            <br />
            complémentaires
          </p>
        </li>
        <li
          onClick={() => {
            params?.set("tab", 5);
            setParams(params);
          }}
          className={tab > 4 ? "active" : ""}
        >
          {" "}
          <button>
            {" "}
            <Icon name="cart1" />
          </button>{" "}
          Checkout
        </li>
      </ul>
    </div>
  );
}

export default Steps;
