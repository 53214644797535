import React from 'react';
import Icon from '../Icon/Icon';

const Card = ({ title, image, description }) => {
  return (
    <div className='bg-white shadow-lg w-full md:w-[27rem] h-full p-[2rem] rounded-[3rem] flex flex-col'>
      <img className='w-full object-cover mb-2' src={image} alt={title} />
      <div className='font-display mt-2 mb-2'>
        <h3 className='text-[20px] font-semibold mb-2 text-left'>{title}</h3>
        <p className=' text-left text-[1.6rem] font-normal '>{description}</p>
      </div>
      <button className='flex items-center justify-center font-normal mt-auto text-[1.6rem] w-full md:w-[23rem] h-[27px] rounded-[0.8rem] text-white bg-black py-[3px] px-[53px] gap-1 '>
        <Icon className='text-[#1576fe]' name='checkbox' />
        Inclus
      </button>
    </div>
  );
};

export default Card;
