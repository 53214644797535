import React, { useState } from 'react';
import './FAQ.css';

function FAQ() {
  const faqs = [
    {
      q: '1. Quels sont les avantages d’une visite virtuelle pour mon restaurant ou hôtel ?',
      r: 'La visite virtuelle permet à vos clients potentiels d’explorer votre établissement à distance, augmentant leur engagement et les incitant à réserver une table ou une chambre. C’est une excellente manière de présenter les atouts uniques de votre espace et d’offrir une expérience immersive.',
    },
    {
      q: '2. Comment se déroule la prise de photos HDR pour mon établissement ?',
      r: 'Notre équipe se rend sur place avec du matériel professionnel pour capturer des photos en haute résolution. Nous utilisons la technologie HDR pour garantir des images de haute qualité, mettant en valeur les espaces sous leur meilleur jour, avec un rendu optimal des couleurs et de la lumière.',
    },
    {
      q: '3. Combien de temps prend la création d’une visite virtuelle ?',
      r: 'La durée dépend de la taille de votre établissement, mais généralement, la capture de la visite virtuelle se fait en une journée. Ensuite, nous nous chargeons du traitement et de l’intégration des éléments interactifs, ce qui prend généralement 2 à 3 jours ouvrés.',
    },
    {
      q: '4. Puis-je mettre à jour la visite virtuelle de mon établissement ?',
      r: 'Oui, nous proposons un service de mise à jour trimestrielle pour ajuster la visite virtuelle en fonction des changements dans votre espace. Cela permet de toujours refléter au mieux les nouveautés de votre établissement.',
    },
    {
      q: '5. Comment la vidéo de la visite virtuelle est-elle utilisée ?',
      r: 'La vidéo de la visite virtuelle peut être utilisée sur votre site internet, vos réseaux sociaux, ou encore dans vos campagnes marketing pour attirer plus de clients. Elle offre une vue immersive et dynamique de votre restaurant ou hôtel.',
    },
    {
      q: '6. Quels sont les bénéfices d’être publié sur Google Street View ?',
      r: 'En étant présent sur Google Street View, vous bénéficiez d’une visibilité accrue auprès d’une audience mondiale. Cela permet à vos clients potentiels de trouver et d’explorer votre établissement directement depuis Google Maps, augmentant vos chances de réservation.',
    },
    {
      q: '7. Qu’est-ce que l’intégration de menu interactif et comment cela fonctionne ?',
      r: 'Nous intégrons votre menu directement dans la visite virtuelle de votre restaurant. Vos clients peuvent ainsi explorer les plats et les commander facilement via un simple clic sur leur smartphone, rendant l’expérience client plus engageante et fluide.',
    },
    {
      q: '8. Quelle est la capacité de stockage dans le cloud et comment puis-je accéder à mes fichiers ?',
      r: 'Nous offrons un espace de stockage de 10 Go dans le cloud pour stocker vos photos HDR, visites virtuelles, et vidéos. Vous pouvez y accéder à tout moment via un portail sécurisé, ce qui vous permet de gérer et partager vos contenus facilement.',
    },
    {
      q: '9. Quels types de statistiques d’engagement sont fournies ?',
      r: 'Nous vous fournissons des rapports détaillés sur l’engagement de vos clients avec votre visite virtuelle : nombre de vues, temps passé dans chaque zone, clics sur les éléments interactifs, et bien plus. Ces informations vous aident à mieux comprendre le comportement de vos clients et à ajuster votre stratégie marketing.',
    },
    {
      q: '10. Quels sont les coûts supplémentaires si mon espace n’est pas prêt lors de la visite programmée ?',
      r: 'Si votre espace n’est pas prêt au moment de notre visite, des frais supplémentaires peuvent s’appliquer en fonction du temps d’attente ou de la nécessité de reprogrammer la capture. Nous vous conseillons donc de bien préparer l’espace avant notre venue.',
    },
    {
      q: '11. Est-ce que je peux annuler ou reprogrammer une session de capture de visite virtuelle ?',
      r: 'Oui, vous pouvez annuler ou reprogrammer une session en nous prévenant au moins 48 heures à l’avance. Si l’annulation est faite moins de 48 heures avant la visite, des frais peuvent s’appliquer.',
    },
    {
      q: '12. Est-ce que je possède les droits des photos, vidéos et visites virtuelles capturées ?',
      r: 'Oui, une fois que le projet est terminé, vous possédez les droits d’utilisation de toutes les photos, vidéos, et visites virtuelles que nous avons créées pour vous.',
    },
    {
      q: '13. Quels types de fichiers vais-je recevoir après la capture ?',
      r: 'Vous recevrez des photos au format haute résolution (JPEG ou PNG), une visite virtuelle hébergée sur notre plateforme, ainsi que des vidéos en format MP4. Tous les fichiers sont optimisés pour une utilisation en ligne et sur les réseaux sociaux.',
    },
    {
      q: '14. Comment puis-je commencer avec vos services ?',
      r: 'C’est très simple ! Il vous suffit de créer un compte sur notre plateforme et de commander votre visite virtuelle ainsi que la publication sur Google Street View. Suivez les étapes détaillées affichées sur notre site pour choisir une date et organiser la capture. Notre équipe s’occupera du reste et reste disponible pour toute question ou assistance supplémentaire.',
    },
  ];
  const [Open, setOpen] = useState(-1);
  return (
    <div className='FAQ'>
      <h2>FAQ</h2>
      {faqs.map((item, i) => (
        <div key={i}>
          <button
            onClick={() => {
              setOpen((o) => (o === i ? -1 : i));
            }}
            className={Open === i ? 'active' : ''}
          >
            {item?.q}
            <img src='/images/icons/add.svg' />
          </button>
          <p>{item?.r}</p>
        </div>
      ))}
    </div>
  );
}

export default FAQ;
