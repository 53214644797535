import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './Contact.css';
import { Link, useNavigate } from 'react-router-dom';

import Icon from '../../components/Icon/Icon';
import { Axios, baseURL } from '../../utils/api/axios';

const Contact = () => {
  const router = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [Loading, setLoading] = useState(false);
  const [Button, setButton] = useState('Envoyez');

  const onSubmit = async (data) => {
    setLoading(true);
    setButton('loading');

    Axios.post(baseURL + 'send-email', {
      name: data.name,
      email: data.email,
      subject: data.subject,
      message: data.message,
    })
      .then(({ data }) => {
        setLoading(false);
        if (data?.success) {
          setButton('Succès');
          reset();
        } else {
          setButton('Echoué');
          // toast.error('Echoué');
        }
      })
      .catch(() => {
        setLoading(false);
        setButton('Echoué');
        // throw new Error('Network response was not ok');
      })
      .finally(() => {
        setTimeout(() => {
          setButton('Envoyez');
        }, 1000);
      });
  };

  return (
    <div className='form-container fixed'>
      <Link to={'/'} className='overlay'></Link>
      <form className='request-form' onSubmit={handleSubmit(onSubmit)}>
        <h4>Contactez-nous</h4>
        <Link className='close' to={'/'}>
          <Icon name='close' />
        </Link>
        <div className='form-row'>
          <div className='form-group'>
            <input
              type='text'
              placeholder='Nom et Prénom'
              {...register('name', {
                required: 'Insérer votre Nom et Prénom ',
              })}
            />
            {errors.name && <p className='error'>{errors.name.message}</p>}
          </div>

          <div className='form-group'>
            <input
              type='text'
              placeholder={'Sujet'}
              {...register('subject', { required: 'Insérer votre Sujet' })}
            />
            {errors.subject && (
              <p className='error'>{errors.subject.message}</p>
            )}
          </div>
        </div>

        <div className='form-group'>
          <input
            type='email'
            placeholder={'Votre Email'}
            {...register('email', {
              required: 'Insérer votre Email',
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: 'Invalid email address',
              },
            })}
          />
          {errors.email && <p className='error'>{errors.email.message}</p>}
        </div>

        <div className='form-group'>
          <textarea
            placeholder={'Message'}
            {...register('message', { required: 'Insérer votre Message' })}
          />
          {errors.message && <p className='error'>{errors.message.message}</p>}
        </div>

        <button type='submit' disabled={Loading}>
          {Button} {Loading && <Icon name='loading-icon' />}
        </button>
      </form>
    </div>
  );
};

export default Contact;
