import React, { useState } from 'react';
import './Checkout.css';
import Icon from '../../Icon/Icon';
import Success from '../../Success/Success';
import { useQuery } from 'react-query';
import { createCapture } from '../../../utils/api/capture';

function Checkout({ Onsubmit, watch, setValue, getValues }) {
  let total = watch('schématique') ? 1200 : 0;
  total += watch('street_view') ? 980 : 0;
  total += Number(watch('superficie') || 0) * 25;
  const [End, setEnd] = useState(false);
  const { data: profile } = useQuery('profile');

  const SaveData = () => {
    let data = { ...getValues() };
    data.proprietaire = profile?._id;
    createCapture({
      ...data,
      proprietaire: data?.proprietaire || profile?._id,
    })
      .then((data) => {
        setEnd(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className='Checkout item-container'>
      {End && <Success />}
      <strong>
        <h1>Récapitulatif (MAD)</h1>
      </strong>
      <div className='line'></div>

      <div className='flex'>
        {/* <button onClick={() => setValue("street_view", false)}>
            <Icon name="close" />
          </button> */}
        <div>
          <img src='/images/3D.png' alt='' srcset='' />
        </div>
        <div>
          <h4>Capture service</h4>
          <span>Restaurant</span>
        </div>
        <div>
          <h4>{Number(watch('superficie') || 0) * 25}</h4>
          <span>Superficie: {watch('superficie') || 0} m2</span>
        </div>
      </div>

      {watch('street_view') && (
        <div className='flex'>
          <button onClick={() => setValue('street_view', false)}>
            <Icon name='close' />
          </button>
          <div>
            <img src='/images/gsv-icon.png' alt='' srcset='' />
          </div>
          <div>
            <h4>Google Street View</h4>
            <span>Restaurant</span>
          </div>
          <div>
            <h4>980,00</h4>
            <span>Superficie: {watch('superficie') || 0} m2</span>
          </div>
        </div>
      )}
      {watch('schématique') && (
        <div className='flex'>
          <button onClick={() => setValue('schématique', false)}>
            <Icon name='close' />
          </button>
          <div>
            <img src='/images/3D1.svg' alt='' srcset='' />
          </div>
          <div>
            <h4>Plan schématique</h4>
            <span>Appartement Maarif</span>
          </div>
          <div>
            <h4>1200,00</h4>
          </div>
        </div>
      )}

      {watch('schématique') && watch('street_view') && (
        <>
          <br />
          <br />
          <div className='line'></div>
        </>
      )}
      <div className='flex'>
        <div>
          <h4>Sous Total (HT)</h4>
        </div>
        <div>
          <h4>{total.toFixed(2)}</h4>
        </div>
      </div>
      <div className='flex'>
        <div>
          <h4>TVA (20%)</h4>
        </div>
        <div>
          <h4>{(0.2 * total).toFixed(2)}</h4>
        </div>
      </div>
      <br />
      <div className='line'></div>
      <div className='flex'>
        <div>
          <h4>Total à payer</h4>
        </div>
        <div>
          <h4>{(0.2 * total + total).toFixed(2)}</h4>
        </div>
      </div>
      <div className='right'>
        <button onClick={SaveData} style={{ marginTop: 20 }} className='add'>
          Commander Maintenant
        </button>
      </div>
    </div>
  );
}

export default Checkout;
