import React from 'react';
import './Tab.css';
import { useSearchParams } from 'react-router-dom';

function Tab({ components = [], tabs = [] }) {
  const [params, setParams] = useSearchParams();
  const tab = Number(params.get('tab'));

  return (
    <div className='Propriete-tab'>
      <div className='tabs-container'>
        {tabs?.map((t, i) => (
          <button
            onClick={() => {
              params?.set('tab', i);
              setParams(params);
            }}
            className={i === tab ? 'active' : ''}
            key={i}
          >
            {t}
          </button>
        ))}
      </div>
      {components[tab]}
    </div>
  );
}

export default Tab;
