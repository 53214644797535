import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';

function Footer() {
  return (
    <>
      <div className='Footer'>
        <div className='container'>
          <div>
            <img src={'/images/logo.svg?v=1'} className='App-logo' alt='logo' />
            <div className='socio'>
              <Link
                to={'https://www.instagram.com/map.lead/?hl=fr'}
                style={{ fontSize: 34 }}
              >
                <Icon name='instagram' />
              </Link>
              <Link to={'/'} style={{ fontSize: 34 }}>
                <Icon name='twitter' />
              </Link>
              <Link
                to={'https://www.facebook.com/profile.php?id=61567495261277'}
                style={{ fontSize: 34 }}
              >
                <Icon name='facebook' />
              </Link>
              <Link
                to={
                  'https://www.linkedin.com/company/maplead-ma/?viewAsMember=true'
                }
                style={{ fontSize: 28 }}
              >
                <Icon name='linkedin' />
              </Link>
            </div>
            <Link to={'/contact'}>
              <button className='reserve'>Contactez-Nous</button>
            </Link>
          </div>
          <div>
            <img src='/images/maps1.png' alt='' />
            <img src='/images/gsv1.png' alt='' />
          </div>
          <div>
            <h3>UNE QUESTION ?</h3>

            <div className='links'>
              <div>
                <Link to={'/'}>
                  <img src='/images/icons/mail.svg' />
                  Contactez-nous par E-mail: Contact@maplead.com{' '}
                </Link>
                <Link to={'/'}>
                  <img src='/images/icons/phone.svg' /> Par Téléphone : +212 522
                  774 323
                </Link>
              </div>
              <div>
                <Link to={'/conditions-utilisation'}>
                  Conditions d’utilisation{' '}
                </Link>
                <Link to={'/politiques-confidentialité'}>
                  Politiques de confidentialité
                </Link>
              </div>
            </div>
            <div className='flex'>
              <img src='/images/items-maps.png' alt='' />
              <img src='/images/gsv1.png' alt='' />
              <img src='/images/gsvt.png' alt='' />
            </div>
          </div>
        </div>
      </div>
      <div className='Footer-end'>
        <div className='container'>
          <p>© 2024 MapLead. - All Rights Reserved</p>
          <img src='/images/maps.png' />
        </div>
      </div>
    </>
  );
}

export default Footer;
