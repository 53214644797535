import React, { useState } from 'react';
import './Module.css';
import Popup from '../Popup/Popup';
import Popup1 from '../Popup/Popup1';

function Module({ register, errors, watch, Onsubmit, setValue }) {
  const [ShowPopup, setShowPopup] = useState(false);
  const [ShowPopup1, setShowPopup1] = useState(false);
  return (
    <div className='Module item-container'>
      {ShowPopup && (
        <Popup watch={watch} setValue={setValue} setShowPopup={setShowPopup} />
      )}
      {ShowPopup1 && (
        <Popup1
          watch={watch}
          setValue={setValue}
          setShowPopup={setShowPopup1}
        />
      )}
      <h4>Mes modules complémentaires</h4>
      <div className='cards'>
        <div className='Card' onClick={() => setShowPopup1(true)}>
          <div className='flex'>
            <img src='/images/3D1.svg' alt='' srcset='' />
            <div>
              <h3>Plan schématique de niveau</h3>
              <span>Par MapLead</span>
            </div>
          </div>
          <p>
            Plans d'étage schématiques professionnels pour la promotion
            d'espaces résidentiels et de locaux commerciaux.
          </p>
        </div>
        <div className='Card' onClick={() => setShowPopup(true)}>
          <div className='flex'>
            <img src='/images/gsv-icon.png' alt='' srcset='' />
            <div>
              <h3>Google Street View</h3>
              <span>Par MapLead</span>
            </div>
          </div>
          <p>
            Ajoutez votre entreprise sur la carte avec l'intégration Google
            Street View de MapLead. MapLead vous permet de publier facilement
            votre espace afin d'accroître sa visibilité.
          </p>
        </div>
        <div className='right'>
          <button
            onClick={() => {
              Onsubmit();
            }}
            style={{ marginTop: 20 }}
            className='add'
          >
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
}

export default Module;
