import React from 'react';
// import './Home.css';
import Header from '../../components/Header/Header';
import Map from '../../components/Map/Map';
import Food from '../../Food/Food';
import StepsCapt from '../../components/StepsCapt/StepsCapt';
import Services from '../../components/Services/Services';
import Plans from '../../components/Plans/Plans';
import NewsletterComponent from '../../components/NewsletterComponent/NewsletterComponent';
import FAQ from '../../components/FAQ/FAQ';
import Footer from '../../components/Footer/Footer';
import Contact from '../Contact/Contact';

const Home = ({ children }) => {
  return (
    <div style={{ paddingTop: '17rem' }} id='hero'>
      {children}
      <div className='container'>
        <Header />
        {/* <Hero /> */}
        {/* <Contact /> */}
        <Map />
        <Food />
        <StepsCapt />
        <Services />
        <Plans />
        <NewsletterComponent />
        <FAQ />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
