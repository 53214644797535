import React from 'react';
import './Map.css';
import { Link } from 'react-router-dom';

function Map() {
  return (
    <div className='Maps' id='a-propos'>
      <h2>
        Bienvenu sur Map
        <span>
          lead <br />
          Le leader des visites virtuelles
        </span>
      </h2>
      <p>
        Spécialiste en photographie 360º, MapLead réalise pour vous le meilleur
        des visites virtuelles dernière génération, avec des résultats
        bluffants... Totalement immergés dans une photographie panoramique haute
        définition, vos internautes découvrent et visitent les lieux à distance,
        interagissent en toute liberté, comme s'ils étaient sur place.
      </p>
      <Link to={'/detail'}>
        <button className='reserve'>Réservez votre visite virtuelle</button>
      </Link>

      <video autoPlay muted loop src='/videos/v1.mp4'></video>
    </div>
  );
}

export default Map;
